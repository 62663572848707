import * as React from "react";
import { Route, Router as BrowserRouter, Switch } from "react-router";
import { useContext, useEffect, Suspense, lazy } from "react";
import { useLocation } from "react-router-dom";
import { GlobalContext } from "./components/contexts/global-context-wrapper/GlobalContextWrapper";
import { history } from "./App";
import GlobalContextModel from "./domain/contexts/globalContext.model";
import clearPageNotFound from "./functions/clearPageNotFound.function";
import clearPageError from "./functions/clearPageError.function";
import MustBeInGroupGuard from "./components/page-guards/MustBeInGroupGuard";
import MustBeInLfgGuard from "./components/page-guards/MustBeInLfgGuard";
import CantBeInGroupGuard from "./components/page-guards/CantBeInGroupGuard";
import CantBeInLfgGuard from "./components/page-guards/CantBeInLfgGuard";
import SecureRoute from "./components/guards/SecureRoute";
import ContentLoadingPage from "./pages/content-loading-page/ContentLoadingPage";

interface Props {}

const ScrollToTop: React.FC<Props> = (): JSX.Element => {
  const { pathname } = useLocation();
  const globalCtx: GlobalContextModel = useContext(GlobalContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    clearPageNotFound(globalCtx);
    clearPageError(globalCtx);
  }, [globalCtx]);

  return null;
};

const Router: React.FC<Props> = (props: Props): JSX.Element => {
  const LaunchpadPage = lazy(() => import("./pages/launch-pad/LaunchpadPage"));
  const LoginPage = lazy(() => import("./pages/login/LoginPage"));
  const ForgottenPasswordPage = lazy(() =>
    import("./pages/forgotten-password/ForgottenPasswordPage")
  );
  const RegisterPage = lazy(() => import("./pages/register/RegisterPage"));
  const DonatePage = lazy(() => import("./pages/donate/DonatePage"));
  const SupportPage = lazy(() => import("./pages/support/SupportPage"));
  const SupportFeedbackPage = lazy(() =>
    import("./pages/support-feedback/SupportFeedbackPage")
  );
  const SupportTicketPage = lazy(() =>
    import("./pages/support-ticket/SupportTicketPage")
  );
  const AwaitingActivationPage = lazy(() =>
    import("./pages/awaiting-activation/AwaitingActivationPage")
  );
  const ActivationPage = lazy(() =>
    import("./pages/activation/ActivationPage")
  );
  const ResetPasswordPage = lazy(() =>
    import("./pages/reset-password/ResetPasswordPage")
  );
  const LogoutPage = lazy(() => import("./pages/logout/LogoutPage"));
  const AccountPage = lazy(() => import("./pages/account/AccountPage"));
  const CharactersViewPage = lazy(() =>
    import("./pages/characters-view/CharactersViewPage")
  );
  const CharacterAddEditPage = lazy(() =>
    import("./pages/character-add-edit/CharacterAddEditPage")
  );
  const ChangePasswordPage = lazy(() =>
    import("./pages/change-password/ChangePasswordPage")
  );
  const ChangeEmailPage = lazy(() =>
    import("./pages/change-email/ChangeEmailPage")
  );
  const LfgPage = lazy(() => import("./pages/lfg/LfgPage"));
  const LfgEditPage = lazy(() => import("./pages/lfg-edit/LfgEditPage"));
  const CreateLfgPage = lazy(() => import("./pages/lfg-create/CreateLfgPage"));
  const GroupPage = lazy(() => import("./pages/group/GroupPage"));
  const GroupEditPage = lazy(() => import("./pages/group-edit/GroupEditPage"));
  const GroupCreatePage = lazy(() =>
    import("./pages/group-create/GroupCreatePage")
  );
  const GroupConsumeInviteCodePage = lazy(() =>
    import("./pages/group-consume-invite-code/GroupConsumeInviteCodePage")
  );
  const NotFoundPage = lazy(() => import("./pages/not-found/NotFoundPage"));

  return (
    <BrowserRouter history={history}>
      <Suspense fallback={<ContentLoadingPage />}>
        <ScrollToTop />
        <Switch>
          {/*Public*/}
          <Route exact path={"/"}>
            <LaunchpadPage />
          </Route>
          <Route exact path={"/home"}>
            <LaunchpadPage />
          </Route>
          <Route exact path={"/login"}>
            <LoginPage />
          </Route>
          <Route exact path={"/forgotten-password"}>
            <ForgottenPasswordPage />
          </Route>
          <Route exact path={"/register"}>
            <RegisterPage />
          </Route>
          <Route exact path={"/donate"}>
            <DonatePage />
          </Route>
          <Route exact path={"/support"}>
            <SupportPage />
          </Route>
          <Route exact path={"/support/ticket"}>
            <SupportTicketPage />
          </Route>
          <Route exact path={"/support/feedback"}>
            <SupportFeedbackPage />
          </Route>
          <Route exact path={"/user/activate"}>
            <AwaitingActivationPage />
          </Route>
          <Route exact path={"/user/:userId/activate/:token"}>
            <ActivationPage />
          </Route>
          <Route exact path={"/user/:userId/reset-password/:token"}>
            <ResetPasswordPage />
          </Route>
          <Route exact path={"/logout"}>
            <LogoutPage />
          </Route>
          {/*Secure*/}
          <SecureRoute exact path={"/account"}>
            <AccountPage />
          </SecureRoute>
          <SecureRoute exact path={"/account/password/change"}>
            <ChangePasswordPage />
          </SecureRoute>
          <SecureRoute exact path={"/account/email/change"}>
            <ChangeEmailPage />
          </SecureRoute>
          <SecureRoute exact path={"/account/characters"}>
            <CharactersViewPage />
          </SecureRoute>
          <SecureRoute exact path={"/account/characters/add"}>
            <CharacterAddEditPage isEdit={false} />
          </SecureRoute>
          <SecureRoute exact path={"/account/characters/:charId"}>
            <CharacterAddEditPage isEdit={true} />
          </SecureRoute>
          <SecureRoute exact path={"/lfg/:userId"}>
            <MustBeInLfgGuard>
              <LfgPage />
            </MustBeInLfgGuard>
          </SecureRoute>
          <SecureRoute exact path={"/lfg/:userId/edit"}>
            <MustBeInLfgGuard>
              <LfgEditPage />
            </MustBeInLfgGuard>
          </SecureRoute>
          <SecureRoute exact path={"/group/create"}>
            <CantBeInGroupGuard>
              <GroupCreatePage />
            </CantBeInGroupGuard>
          </SecureRoute>
          <SecureRoute exact path={"/group/join"}>
            <CantBeInLfgGuard>
              <CreateLfgPage />
            </CantBeInLfgGuard>
          </SecureRoute>
          <SecureRoute exact path={"/group/:groupId/invite/:inviteCode/accept"}>
            <CantBeInGroupGuard>
              <GroupConsumeInviteCodePage />
            </CantBeInGroupGuard>
          </SecureRoute>
          <SecureRoute exact path={"/group/:groupId/edit"}>
            <MustBeInGroupGuard>
              <GroupEditPage />
            </MustBeInGroupGuard>
          </SecureRoute>
          <SecureRoute exact path={"/group/:groupId"}>
            <MustBeInGroupGuard>
              <GroupPage />
            </MustBeInGroupGuard>
          </SecureRoute>
          {/*Catch all*/}
          <Route path={"*"}>
            <NotFoundPage />
          </Route>
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default Router;
