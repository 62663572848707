import styled from "styled-components/macro";

const DivEsoBackground = styled.div`
  background-image: url("https://content.esolfg.com/images/background.jpg");
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 300ms ease-in-out;
`;

export default DivEsoBackground;
